import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Stepper component is a UI element that helps users navigate through a process or workflow by breaking it down into smaller, sequential steps.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/stepper/guideline-stepper-anatomy.svg",
      "alt": "guideline stepper"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Step Label`}</strong>{`: Describes the current step in the process.`}</li>
      <li parentName="ol"><strong parentName="li">{`Step Icon`}</strong>{`: A visual indicator of the current step.`}</li>
      <li parentName="ol"><strong parentName="li">{`Step Content`}</strong>{`: The content for each step in the process, which could include instructions, forms, or other UI elements.`}</li>
      <li parentName="ol"><strong parentName="li">{`Step Connector`}</strong>{`: A line or other visual element that connects each step in the process.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Stepper component is commonly used in forms, onboarding flows, and other multi-step processes. It helps users understand where they are in the process and what steps they need to take next.`}</p>
    <p><strong parentName="p">{`Common places buttons appear`}</strong>{`: Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>When a process or workflow has multiple steps that need to be completed in a specific order.</li>
      <li>When users may need to go back and change previous steps in the process.</li>
      <li>When users need to understand the progress they have made and how much is left to complete.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When the process or workflow only has one or two steps.</li>
      <li>When the steps in the process are not sequential or do not need to be completed in a specific order.</li>
      <li>When the Stepper component would make the interface too cluttered or complex.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure accessibility, the Stepper Component should:`}</p>
    <ul>
      <li parentName="ul">{`Each step in the Stepper component should be clearly labeled and easy to distinguish from other steps.`}</li>
      <li parentName="ul">{`The Stepper should be usable with keyboard navigation, and each step should be focusable.`}</li>
      <li parentName="ul">{`Use ARIA attributes to help users understand the purpose and function of the Stepper component.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use the Stepper Component for small ranges of values. So the users can understand and see the step without it being tedious." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/stepper/guideline-stepper-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use the Stepper Component for large ranges of values. Because it can be tedious for users to increment or decrement the value multiple times to reach the desired value." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/stepper/guideline-stepper-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Stepper :`}</p>
    <ul>
      <li parentName="ul">{`Step Label: Descriptive label for each step in the process.`}</li>
      <li parentName="ul">{`Step Icon: An easily recognizable icon or symbol that represents each step.`}</li>
      <li parentName="ul">{`Step Connector: A line or other visual element that connects each step in the process.`}</li>
      <li parentName="ul">{`Step Content: The content for each step in the process, which could include instructions, forms, or other UI elements.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use clear, concise labels or icons for each tab element. So it doesn't causes some confusion and misunderstanding for the users in using Tab Bar component. " aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/stepper/guideline-stepper-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use unclear, vague labels or icons for each tab element. Because it could causes some confusion and misunderstanding for the users in using Tab Bar component. " aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/stepper/guideline-stepper-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      